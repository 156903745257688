// color primary
$color-primary-1: #030E29;
$color-primary-2: #081B4B;
$color-primary-3: #2347A5;
$color-primary-4: #4674eb;
$color-primary-5: #6B90EF;
$color-primary-6: #9DB4F0;
$color-primary-7: #DBE2F3;
// color accents
$color-accent-orange-1: #D4820A;
$color-accent-orange-2: #F29F24;
$color-accent-orange-3: #FFCF87;
$color-accent-orange-4: #FFE8C6;
$color-accent-red-1: #CC3E35;
$color-accent-red-2: #F64F45;
$color-accent-red-3: #FF928B;
$color-accent-red-4: #FFDFDC;
$color-accent-green-1: #34931E;
$color-accent-green-2: #4DC033;
$color-accent-green-3: #8ADC76;
$color-accent-green-4: #D0F5C7;
// color neutral
$color-neutral-1: #1E1E1E;
$color-neutral-2: #707070;
$color-neutral-3: #B4B4B4;
$color-neutral-4: #E8E8E8;
$color-neutral-5: #F2F2F2;
$color-neutral-6: #F6F6F6;
$color-neutral-7: #ffffff;
$color-neutral-8: #F6F9FF;
$color-neutral-9: #D2D2D2;

// tooltip color
$tooltip-arrow-color: $color-primary-4;
$tooltip-bg: $color-primary-4;
